<template>
  <article>
    <hgroup><h1>TV화면 캡쳐 컴포넌트 개발하기</h1></hgroup>
    <h2>Prerequisites</h2>
    <ul class="checklist">
      <li>Python 3.7</li>
      <li>Flask</li>
    </ul>
    <div>
      <div style="margin-top: 26px;">
        Public component인 <a href="https://developer.damda.lge.com/docs/webos-tv/api" target="_blank">webOS TV</a>를 활용하여 TV의 화면을 캡처하는 애플리케이션을 컴포넌트로 생성하여 배포하는 튜토리얼입니다.
        <br />아래 화면은 최종적으로 TV 화면 캡처 컴포넌트를 기기에 배포한 후 실행 화면입니다.
        <img
            src="/assets/img/example1.png"
            alt=""
            style="margin-bottom: 60px; margin-top: 10px;"
        />
      </div>
    </div>
    <h2><span class="chip">1단계</span>웹 애플리케이션 개발</h2>
    <ol>
      <li><a href="/assets/img/damda_tutorial_1_tvcapture.zip" target="_blank"> 샘플 코드</a> 다운로드</li>
      <li>
        app.py에서 "#TODO 여기에 코드를 작성 하세요." 위치에 아래 코드를 추가하세요.
      </li>
    </ol>
    <code-viewer language="python" :code="getStartedPage2Py" />
    <div>
      <div style="margin-top: 44px;">
        [참고]<br />
        webOS TV 컴포넌트로 아래 API를 사용하여 TV를 등록하고 화면을 캡처합니다.<br />
        API를 호출하는 Flow는<router-link to="/docs/webos-tv/api" style="margin-left: 8px;">이 페이지</router-link>를 참고하세요.
      </div>
      <ul style="margin-top: 29px; margin-bottom: 102px;">
        <li>
          TV 리스트 조회
          <router-link to="/docs/webos-tv/reference#tv-list" style="margin-left: 8px;">GET /webostv</router-link>
        </li>
        <li>
          TV 등록
          <router-link to="/docs/webos-tv/reference#tv-enroll" style="margin-left: 8px;">POST /webostv/{ip}</router-link>
        </li>
        <li>
          TV 화면 캡쳐
          <router-link style="margin-left: 8px;" to="/docs/webos-tv/reference#capture">POST /webostv/{ip}/control/capture</router-link>
        </li>
      </ul>
    </div>
    <h2><span class="chip">2단계</span>컴포넌트 생성</h2>
    <div>
      <ol>
        <li>예제 코드 파일 압축 (파일명 : damda_tv_capture.zip)</li>
        <ul class="comment">
          <li>app.py</li>
          <li>requirements.txt</li>
          <li>static/*</li>
          <li>templates/*</li>
        </ul><br>
        코드구조
        <img
          src="/assets/img/getstarted9.png"
          alt=""
          style="margin-bottom: 60px; margin-top: 10px;"
        />
        <li style="margin-bottom: 60px;">
          <a href="https://damda.lge.com/" target="_blank"> DAMDA console</a> 로그인 > 컴포넌트 메뉴에서 컴포넌트 추가
        </li>
        <li>컴포넌트 생성을 위해 필요한 정보 입력</li>
        <ul style="line-height:34px">
          <li>
            컴포넌트명 : "com.damda.sample.webostv" 입력<br>
            - Unique한 이름을 지정해야 함. damda 플랫폼 내 중복되는 이름의 컴포넌트가 존재하는경우 컴포넌트 생성 불가<br>
            - 컴포넌트명 추천 형식 : com.&lt;개인 식별키워드&gt;.sample.webostv
          </li>
          <li>설명 : ‘TV 화면을 캡쳐하는 예제’ 입력</li>
          <li>
            코드 : '1. 예제 코드 파일 압축' 단계에서 생성한 'tv_capture.zip' 파일 업로드
          </li>
          <li>
            실행 스크립트 : "Python" 선택. 코드가 디바이스에 설치 된 후 디바이스에서 실행될 스크립트
          </li>
          <code-viewer :code="getStartedPage2Sh" language="bash" />
          <li>홈 화면 출력' 체크</li>
          <li>썸네일 이미지 : 자유롭게 선택</li>
          <li>앱 이름 : 'TV 캡처' 입력</li>
          <li>
            접속 링크 : '<span class="link-font">http://127.0.0.1:8005</span>' 입력 <br />
            - 홈 화면에서 썸네일 클릭 시 이동할 링크 주소
          </li>
        </ul>
        <img
          src="/assets/img/getstarted11.png"
          alt=""
          style="margin-top: 60px; margin-bottom: 60px;"
        />
        <li>컴포넌트 생성 완료</li>
      </ol>
      <img
        src="/assets/img/getstarted12.png"
        alt=""
        style="margin-top: 43px; margin-bottom: 100px;"
      />
    </div>
    <h2><span class="chip">3단계</span>컴포넌트 배포</h2>
    <div>
      <ol>
        <li>배포할 컴포넌트를 모두 선택 > 배포하기</li>
        <ul>
          <li>Public 컴포넌트는 모두 배포 리스트에 기본으로 포함됩니다.</li>
        </ul>
        <img
          src="/assets/img/getstarted13.png"
          alt=""
          style="margin-top: 51px; margin-bottom: 60px;"
        />
        <li>
          배포할 컴포넌트 리스트를 확인 &gt; 배포할 '코어 디바이스 선택' &gt; 배포 요청
        </li>
        <img
          src="/assets/img/getstarted14.png"
          alt=""
          style="margin-top: 43px; margin-bottom: 60px;"
        />
        <li>
          배포가 완료된 후 개발PC 브라우저에서 '<span class="link-font">http://{라즈베리파이의 IP}:8002</span>' 접속
        </li>
        <ul>
          <li>
            참고 : 라즈베리파이에서 접속 시 '<span class="link-font">http://localhost:8002</span>'로 접속합니다.
          </li>
        </ul>
        <img
          src="/assets/img/getstarted15.png"
          alt=""
          style="margin-top: 45px; margin-bottom: 60px;"
        />
        <li>아이콘을 클릭하여 배포한 컴포넌트 웹 애플리케이션 실행</li>
        <ul>
          <li>
            3~4초 후 같은 네트워크에 연결된 TV 리스트가 나타납니다. 연결한 TV를 선택하고 TV 등록 절차를 참고하여 연결합니다.
          </li>
          <li>
            연결 완료 Toast가 뜨면 캡처 버튼을 눌러 TV 스크린샷이 잘 캡쳐되는지 확인합니다.
          </li>
        </ul>
      </ol>
      <img
        src="/assets/img/getstarted16.png"
        alt=""
        style="margin-top: 48px;"
      />
    </div>
  </article>
</template>
<script>
import getStartedPage2Py from "raw-loader!@/assets/code/get-started-page2.py";
import getStartedPage2Sh from "raw-loader!@/assets/code/get-started-page2.sh";
import CodeViewer from "@/components/CodeViewer.vue";
export default {
  data() {
    return {
      getStartedPage2Py,
      getStartedPage2Sh,
    };
  },
  components: {
    CodeViewer,
  },
};
</script>
